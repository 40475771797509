export const allRoutes = {
  login: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  subAdmins: '/settings/sub-admins',
  roles: '/settings/roles',
  addSubAdmin: '/settings/sub-admins/add',
  editSubAdmin: (id) => `/settings/sub-admins/edit/${id}`,
  articleList: '/my-article',
  liveEvents: '/live-events',
  editEventContent: (id) => `/live-events/edit-event-content/${id}`,
  addEvent: '/live-events/add',
  editEvent: (id) => `/live-events/edit/${id}`,
  addArticle: '/my-article/create-new-article',
  editArticle: (id) => `/my-article/edit-article/${id}`,
  tags: '/management/tags',
  commentary: '/commentary',
  editCommentary: (id, inn, totInn, tit) => `/commentary/edit/${id}/${tit}/${totInn}/${inn} Inning`,
  addTag: '/management/tags/add-tag',
  editTag: (id) => `/management/tags/edit-tag/${id}`,
  categories: '/management/categories',
  addCategory: '/management/categories/add-category',
  editCategory: (id) => `/management/categories/edit-category/${id}`,
  manageSeo: (id) => `/management/categories/edit-category/${id}/manage-seo`,
  articleComments: '/comments/article',
  fantasyArticleComments: '/comments/fantasy-article',
  editProfile: '/dashboard/profile',
  addFantasyTips: (type, id) => `/fantasy-tips/create-new-post/${type}/${id}`,
  editFantasyTips: (type, id) => `/fantasy-tips/edit-article/${type}/${id}`,
  editFantasyOverview: (id) => `/fantasy-tips/edit-overview/${id}`,
  fantasyTipsList: '/fantasy-tips',
  feedbacks: '/help/feedbacks',
  contacts: '/help/contacts',
  detailContact: (id) => `/help/contacts/details/${id}`,
  detailFeedback: (id) => `/help/feedbacks/details/${id}`,
  youtubeVideo: '/settings/youtube-video',
  players: '/management/players',
  editPlayer: (id) => `/management/players/edit/${id}`,
  editTeam: (id) => `/management/teams/edit/${id}`,
  fetchplaylistSuccess: '/fetch-playlist-success',
  teams: '/management/teams',
  seo: '/settings/seo',
  addSeo: '/settings/seo/add-seo',
  editSeo: (type, id) => `/settings/seo/${type}/${id}`,
  seoRedirects: '/settings/seo-redirects',
  jobPost: '/settings/job-post',
  detailEnquiry: (id) => `/settings/enquiry-detail/${id}`,
  addJobPost: '/settings/job-post/add-job-post',
  editJobPost: (type, id) => `/settings/job-post/${id}/${type}`,
  tagMigrationManagement: '/settings/tag-migration-management',
  endUsers: '/settings/end-users',
  homePageArticle: '/settings/homepage-article',
  detailEndUser: (id) => `/settings/end-users/details/${id}`,
  cms: '/settings/cms',
  addCms: '/settings/cms/add-cms',
  editCms: (id) => `/settings/cms/edit-cms/${id}`,
  syncStats: '/settings/sync-stats',
  arrangeMenu: '/settings/arrange-menu',
  authors: 'authors/',
  careers: 'careers/',
  ads: '/settings/ads/',
  appAds: '/settings/app-ads/',
  media: '/media-gallery',
  adsCampaign: '/ads-campaign',
  editAdsCampaignUnits: (id) => `/ads-campaign/edit-ads-campaign-units/${id}`
}
